

.wallet_table_div{
    background: #fff;
    border-radius: 12px;
    filter: drop-shadow(0 30px 70px rgba(26,66,149,.25));
    padding: 10px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}

.wallet_card_div{
    background: linear-gradient(101.43deg, rgba(26, 55, 78, 0.9) -2.83%, #1A374E 73.19%);
    border-radius: 20px;
    padding: 20px;
}

.wallet_value_div{
    display: flex;
    justify-content: space-between;
}


.wallet_title{
    color: #fff;
    font-family: 'GraphixMedium';
    margin-top: 20px;
}

.wallet_subtitle{
    color: #fff;
    font-family: 'GraphixSemibold';
    margin-bottom: 0px;
    font-size: 18px;
}

.widthraw_div{
    text-align: right;
    background:  linear-gradient(90deg, rgba(247, 147, 26, 0) 70%, #F7931A 140%);
    border-radius: 0px 5px 5px 0px;
    padding: 10px;
    cursor: pointer;
}

.widthraw_div a{
    color: #F7931A;
    font-family: 'Averta';
    margin-bottom: 0px;
    font-weight: 400;
    text-decoration: none;
}

.credit_card_div{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.15);
    border-radius: 10px;
    padding: 20px;
}

.credit_card_value{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.credit_title{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'GraphixMedium';
    margin-top: 20px;
}

.credit_subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'GraphixSemiBold';
}


.trade_card_div{
    background: rgba(237, 240, 246, 1);
    border-radius: 12px;
    padding: 20px;
}

.trade_card_title_div{
    display: flex;
    align-items: center;
}

.trade_title{
    color: rgba(0, 0, 0, 0.6);
    font-family: 'GraphixSemiBold';
    letter-spacing: 0.342857px;
}

.trade_subtitle{
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.428571px;
    font-size: 14px;
}

.trade_btn{
    color: #fff;
    border-radius: 5px;
    background: #F7931A;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
}

.trade_btn:hover{
    background: rgba(247, 147, 26, 0.8);
    color: #fff;
}

.wallet_filter_container{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}


.input_style{
    border: 1px solid rgba(1,34,101,.2);
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  
.filter_div{
    border:  1px solid #1A374E;
    border-radius: 5px;
     padding: 14px 45px;
     color: #333A43;
     transition: all 0.15s ease-in-out;
    width: 100%;
    font-size: 14px;
}

.filter_input_style{
    border: 1px solid #F7F7F8;
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F7F7F8;
    appearance: none;
    cursor: pointer;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.filter_input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.filter_input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_filter{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_filter:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}

.withdraw_card{    
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 40px 20px;
}

.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}


.withdraw_input_style{
    border: 1px solid #F4F2F2;
    border-radius: 6px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F4F2F2;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.withdraw_input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .withdraw_input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_withdraw{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_withdraw:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}


.nowithdraw_card{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 40px 20px;  
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.no_account_title{
    color: rgba(26, 55, 78, 0.8);
    letter-spacing: 0.396px;
}

.no_account_subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
    letter-spacing: 0.396px;
}


/* details poage */
.details_card{
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 20px;
}

.sent_card{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.15);
    border-radius: 10px;
    padding: 25px 25px;
}


.sent_sect{
    display: flex;
    align-items: center;
}

.sent_title{
    color: rgba(26, 55, 78, 0.8);
}

.sent_subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'GraphixMedium';
    margin-bottom: 0px;
}

.details_title{
    color: #2C3A50;
    font-family: 'GraphixMedium';
    margin-bottom: 0px;
}

.details_value{
    color: rgba(44, 58, 80, 0.8);
    font-family: 'Averta';
    margin-bottom: 0px;
}


.pending_badge{
    color: #FFD66B;
    background: rgba(255, 214, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
}

.success_badge{
    color: #9CC031;
    background: rgba(156, 192, 49, 0.1);
    border-radius: 100px;
    padding: 5px 20px;   
}

.failed_badge{
    color: #FF8F6B;
    background: rgba(255, 143, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px; 
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .wallet_card_div{
        background: linear-gradient(101.43deg, rgba(26, 55, 78, 0.9) -2.83%, #1A374E 73.19%);
        border-radius: 20px;
        padding: 20px;
    }
    .wallet_filter_container{
        display: flex;
        flex-direction: row;
        padding: 20px 20px 10px 20px;
    }
    .wallet_filter_container div:first-child{
        flex: 1;
    }
    .withdraw_card{    
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
        border-radius: 20px;
        padding: 40px 80px;
    }
    .nowithdraw_card{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
        border-radius: 20px;
        padding: 60px 50px;  
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center; 
    }
    .btn_withdraw{
        padding: 14px 60px;
        width: auto;
    }

    .details_card{
        width: 70%;
        padding: 50px 50px;
    }
}


/* large screens */
@media (min-width: 1536px) {
    .wallet_card_div{
        background: linear-gradient(101.43deg, rgba(26, 55, 78, 0.9) -2.83%, #1A374E 73.19%);
        border-radius: 20px;
        padding: 30px;
    }
    .credit_card_div{
        padding: 30px;
    }
    .trade_card_div{
        background: #EDF0F6;
        border-radius: 12px;
        padding: 30px;
    }
}