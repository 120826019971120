
.card_lay{
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    padding: 40px 20px;
    border: 1px dashed rgba(26, 55, 78, 0.4);
    border-radius: 10px;
}

.card_title{
    text-align: center;
    color: #1A374E;
    font-weight: 400;
}


.tab_div{
    margin-top: 10px;
    background: rgba(247, 147, 26, 0.05);
    border: 1px solid rgba(26, 55, 78, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 8px;
}

.tab_list{
    list-style: none;
    margin-bottom: 0px;
    display: flex;
}

.tab_list li{
    flex-basis: 50%;
    cursor: pointer;
}

.tab{
    margin-bottom: 0px;
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
    text-align: center;
    padding: 8px;
}

.tab_active{
    background: #F7931A;
    border-radius: 10px;
    color: white;
}

/* crypto styles */
.coin_div{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 10px;
    padding: 35px 20px;
}


.coin_result_div{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    background: #fff;
    border-radius: 12px;
    padding: 10px;
}

.coin_img img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.coin_text{
    max-width: 30rem;
    margin: auto;
    text-align: center;
    color: rgba(0,0,0,.6);
    font-weight: 400;
    line-height: 20px;
    z-index: 9;
    font-size: 15px;
    font-family: 'Averta';
}

.input_container{
    position: relative;
}

.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style{
    border: 1px solid #F7F7F8;
    border-radius: 6px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F7F7F8;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }


  .btn_sell{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'Graphix';
  }

  .btn_sell:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
  }

  /* card styles */
  .giftcards_div{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
    border-radius: 20px;
    padding: 50px 20px;
  }


  .amount_style{
    position: absolute;
    right: 0;
    text-align: center;
    background: #fff;
    border: 1px solid rgba(26, 55, 78, 0.2);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 100px;
    height: 52.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .amount_div{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
.amount_input{
    border:  1px solid rgba(26, 55, 78, 0.2);
    border-radius: 6px;
    padding: 14px 20px;
    background: #fff;
    font-size: 15px;
    width: 100%;
    font-weight: bold;
    color: #1A374E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  
  .amount_input::placeholder{
    color: #1A374E;
  }

  .amount_input:disabled{
    color: #1A374E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 1;
  }
  

  .alert_info{
      display: flex;
      align-items: center;
  }

  .alert_title{
      margin-bottom: 0px;
      color: rgba(26, 55, 78, 0.8);
      font-family: 'Averta';
      font-size: 14px;
      line-height: 24px;
  }

  .btn_trade{
    padding: 14px 80px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: auto;
    font-family: 'Graphix';  
  }

  .btn_trade:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);   
  }

  .cursor_pointer {
    cursor: pointer;
}

.input_group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input_group>.custom_file {
    display: -ms-flexbox;
    display: flex;
    /* border: 1px solid rgba(8, 152, 215, 0.2); */
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.custom_file, .custom_file_input {
    height: calc(49px + 2px);
    cursor: pointer;
}
.custom_file_input {
    height: calc(49px + 2px);
    cursor: pointer;
}
.custom_file_input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
}
.custom_file_label {
    padding: 14px 80px 14px 18px;
    height: calc(49px + 2px);
}
.custom_file_label {
    position: absolute;
    /* top: 0; */
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0px;
    z-index: 1;
    line-height: 1.5;
    color: #1A374E;
    background: #F7F7F8;
    border: 1px solid #F7F7F8;
    border-radius: .25rem;
    cursor: pointer;
}
.custom_file_label::after {
    position: absolute;
    /* top: 5px; */
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 3;
    display: block;
    content: "Browse";
    background: transparent;
    border-left: none;
    border-radius: 0 .25rem .25rem 0;
    color: #1A374E;
}

.custom_file_input:focus {
    border: none;
    box-shadow: none;
    outline: 0 none;
}

.fileupload{
    margin-top: 10px;
    position: relative;
}
.imageFile{
    position: relative;
    width: fit-content;
    margin: 0 15px 15px 0;
}
.imageFile .close_icon {
    position: absolute;
    top: 0px;
    right: -6px;
    cursor: pointer;
    z-index: 1;
}
.close_icon {
    width: 22px;
    height: 22px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAhtJREFUOBG1lc9LAlEQx2clDUUhA1ESAiHRi0H37L6esvVPCfobhI52tj+g7cfJztk93JNiIASmsqCBoaTJNt/VlbXeVhbOYd9j3sznzZs3b1YiB2k0Ghv9fj/Ly4okSbuGYYRgynOd5xpP1UAgcBWLxV6g/yzSZ0W9Xl8fDAbHDDjx+/1SMBj0e71ecrvdpul4PKbhcEi9Xu+Vhfcw8j6f7zQej7/ZWQvgarW6NRqNSgzciUajPo/HY7f9MmdbajabA97gkW3lZDL5bBnNwTPoQyQS2QyFQmuWwW9GXdff2+12l+F7FtwFRxwfkf4FCn8EAl8wwILOBCOnOP6ykQJgCXzBAAs6aXb7T4lEIvBTTi2I04ic12q1PlfLtgslhdv/LxSbgQEWmEiFgpJyiqJYLFKn01lY5ouic9aLZMZSXCh+1KmTyLJM6sXFHA7opaqSnMkIXcACkzRNG04mExS6o7RaLeOsUDAqlYo5MtzRFiwwzaoQbm1TcinRfjpNN9fXlD44oHA4bFsVT5EKHc/0O8Hx78tlOsxmqXx3N0+LyAcsMF18Jg1v30msnCq5HKVSKcKInEMvErDARCpUNBSREXS3pZIJs46P8UhRTL3IZ8ZSV/dA0E859Dy6lCiCZXRggAWmWRXop2h96FLLgOy28AUDLOhNMJo0P0eZL6T7F7itbcpWw5/3Y+yykkYPMGQlv6Ypevr978/0AyH3oBomU94UAAAAAElFTkSuQmCC) no-repeat;
}

.qr_result_div{
    min-height: 300px;
    background: #fff;
    border-radius: 12px;
    padding: 50px 20px;
}

.qr_title{
    text-align: center;
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.qr_code_div{
    border-radius: 8px;
    width: 250px;
    height: 250px;
    margin: auto;
    background: #fff;
    padding: 15px;
}


.qr_input_container{
    position: relative;
}
.copy_content{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    cursor: pointer;
    background: #FAFBFE;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.qr_input_style{
    border: 1px solid #F7F7F8;
    border-radius: 6px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #F7F7F8;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.qr-input-style:disabled{
    background: #FFFFFF; 
}

.qr-input-style:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
    outline: 0 none;
}

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .card_lay{
        min-height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card_div{
        display: flex;
        justify-content: center;
        min-width: 450px;
        padding: 60px 60px;
        border: 1px dashed rgba(26, 55, 78, 0.4);
        border-radius: 10px;
    }

    .coin_result_div{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 600px;
        background: #fff;
        border-radius: 12px;
        padding: 0px;
    }

    .qr_result_div{
        min-height: 300px;
        background: #fff;
        border-radius: 12px;
        padding: 50px 50px;
    }

    .coin_img img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    .coin_text{
        max-width: 30rem;
        margin: auto;
        text-align: center;
        color: rgba(0,0,0,.6);
        font-weight: 400;
        line-height: 20px;
        z-index: 9;
        font-size: 15px;
        font-family: 'Averta';
    }

    .giftcards_div{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.2);
        border-radius: 20px;
        padding: 50px 50px;
      }
    
}



/* large screens */
@media (min-width: 1536px) {
}