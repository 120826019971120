

.account_div{
    background: #fff;
    border-radius: 12px;
    filter: drop-shadow(0 30px 70px rgba(26,66,149,.25));
    padding: 10px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}

.add_account_div{
    background: #FFFFFF;
    border-radius: 10px 0px 0px 10px;
    padding: 25px 25px 50px 25px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.15);
}

.add_title{
    color: #1A374E;
    font-weight: 600;
}


.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style{
    border: 1px solid rgba(1,34,101,.2);
    border-radius: 5px;
    font-size: 14px;
    color: #1A374E;
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'Averta';
  }

  .btn_add{
    padding: 14px 40px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    width: 100%;
    font-family: 'GraphixMedium';
  }

  .btn_add:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
}


/* large screens */
@media (min-width: 1536px) {
}