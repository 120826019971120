.auth_left_section{
    background: linear-gradient(180deg, #204A6C 0%, #1B3850 84.79%, #173247 100%);
    min-height: 100vh;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.auth_title{
    color: #E69A25;
    font-size: 3rem;
    padding-left: 4rem;
    font-family: 'GoodTimes';
    margin-bottom: 0px;
}

.auth_subtitle{
    color: #fff;
    padding-left: 4rem;
    font-family: 'Averta';
}

.auth_logo_div{
    position: absolute;
    bottom: 40px;
    left: 60px;
}

.auth_logo_div img{
    width: 100px;
    height: 100px;
}

.auth_circle_div{
    position: absolute;
    bottom: 0px;
}

.auth_right_section{
    padding: 50px 25px;
}

.logo{
    width: 3.5rem;
}

.right_title{
    color: #1A374E;
    font-family: 'Graphix';
    margin-bottom: 0px;
    font-size: 2rem;
}
.right_subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.resetexpiredtext{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';   
}

.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style {
    border: none;
    border-radius: 6px;
    padding: 14px 20px;
    background: #F4F2F2;
    font-size: 15px;
    width: 100%;
    color: #1A374E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(44, 58, 80, 0.6);
    font-size: 12px;
    font-family: 'Averta';
  }

  .email_style{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    width: 20px;
    height: 20px;
  }


  .toggle_style{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }


.forgot_password_text{
font-size: 14px;
color: #1A374E;
font-family: 'Averta';
}

.btn_login_submit{
    padding: 14px 30px;
    background: #E69A25;
    border: 1px solid #E69A25;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
    font-family: 'Averta';
  }

  .btn_login_submit:hover{
    color: #fff;
    background: rgb(230, 154, 37, 0.9);
}

.login_link{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
    font-size: 14px;
    font-weight: 500;
}

.login_link a{
    color: #E69A25;
    text-decoration: none;
}

.btn_register_submit{
    padding: 14px 30px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
    font-family: 'Averta';
  }

  .btn_register_submit:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}


.verify_div{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.back_home_title{
    font-family: 'Graphix';
    color: #1A374E;
    font-size: 14px;
    letter-spacing: 0.396px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.verify_card_div{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.verify_card{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 60px 40px;
}

.verify_icon_div{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #F4F4F4;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify_icon_div img{
    width: 50px;
    height: 50px;
}

.verify_title{
    color: #1A374E;
}

.verify_subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
    letter-spacing: 0.396px;
}

.verify_link_div{
    text-align: center;
    margin-top: 60px;
}

.verify_link_div p{
    color: #1A374E;
    font-size: 14px;
}

.link_status{
    color: #F7931A;
    cursor: pointer;
}

.link_status:hover{
    color: #F7931A;
}

.link_disabled{
    opacity: 0.65;
    pointer-events: none;
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .auth_right_section{
        padding: 50px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        height: 100%;
    }
    .verify_card{
        background: #FFFFFF;
        border-radius: 10px;
        padding: 60px 100px;
    }
    .verify_icon_div{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #F4F4F4;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .verify_icon_div img{
        width: 60px;
        height: 60px;
    }
    .verify_subtitle{
        color: rgba(26, 55, 78, 0.8);
        font-family: 'Averta';
        letter-spacing: 0.396px;
        max-width: 20rem;
    }
    
}

/* large screens */
@media (min-width: 1536px) {
    .auth_right_section{
        padding: 50px 50px;
    }
}