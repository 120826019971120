
.terms_content{
    color: rgba(0,0,0,.7);
}

.terms_heading h4{
    color: #2C3A50;
    font-family: 'GraphixMedium';
}

.terms_content p{
    color: rgba(0,0,0,.7);
}

.terms_content p a{
    text-decoration: none;
    color: #1C4594;
}

.terms_content h6 {
    font-family: 'GraphixSemibold';
    color: rgba(0,0,0,.7);
}



/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
}


/* large screens */
@media (min-width: 1536px) {
}