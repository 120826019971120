
.wallet_card_div{
    background: linear-gradient(101.43deg, rgba(26, 55, 78, 0.9) -2.83%, #1A374E 73.19%);
    border-radius: 20px;
    padding: 20px;
}
.pending_card_div{
    background: #FFFFFF;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.15);
    border-radius: 10px;
    padding: 20px;
}

.wallet_value_div{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.wallet_title{
    color: #fff;
    font-family: 'GraphixMedium';
    margin-top: 20px;
}

.wallet_subtitle{
    color: #fff;
    font-family: 'GraphixSemibold';
    margin-bottom: 0px;
    font-size: 1rem;
}

.widthraw_div{
    text-align: right;
    background:  linear-gradient(90deg, rgba(247, 147, 26, 0) 70%, #F7931A 140%);
    border-radius: 0px 5px 5px 0px;
    padding: 10px;
    cursor: pointer;
}

.widthraw_div a{
    color: #F7931A;
    font-family: 'Averta';
    margin-bottom: 0px;
    font-weight: 400;
    text-decoration: none;
}

.pending_value_div{
    display: flex;
    align-items: center;
}

.pending_title{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'GraphixMedium';
}

.pending_subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'GraphixBold';
}

.trade_card_div{
    background: #EDF0F6;
    border-radius: 12px;
    padding: 20px;
}

.trade_card_title_div{
    display: flex;
    align-items: center;
}

.trade_title{
    color: rgba(0, 0, 0, 0.6);
    font-family: 'GraphixSemiBold';
    letter-spacing: 0.342857px;
}

.trade_subtitle{
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.428571px;
    font-size: 14px;
}

.trade_btn{
    color: #fff;
    border-radius: 5px;
    background: #F7931A;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
}

.trade_btn:hover{
    background: rgba(247, 147, 26, 0.8);
    color: #fff;
}

.dash_transact_container{
    background: #fff;
    border-radius: 12px;
    filter: drop-shadow(0 30px 70px rgba(26,66,149,.25));
    padding: 10px;
}


.pending_transact_title{
    color: rgba(0, 0, 0, 0.6);
    font-family: 'GraphixMedium';
    font-size: 18px;
}

.recent_table_row{
    color: rgba(26, 55, 78, 0.9);
    font-family: 'Averta';
}

.analytics_div{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
}
.analytics_title{
    color: rgba(3, 2, 41, 0.7);
    font-weight: bold;
}

.title_modal{
    letter-spacing: 0.396px;
    color: #1A374E;
    font-weight: 500;
    max-width: 18rem;
    margin: auto;
}

.pin_title{
    color: #2D2D2D;
    font-family: 'GraphixMedium';
}

.pin_heading{
    color: #1A374E;
    font-family: 'Averta';
    font-size: 14px;
}

.pin_container{
    max-width: 22rem;
    margin: auto;
}



.confirm_pinbtn{
    color: #fff;
    border-radius: 5px;
    background: #1A374E;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;  
}

.confirm_pinbtn:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}

.pin_footer_text{
    letter-spacing: 0.396px;
    color: rgba(26, 55, 78, 0.8);
    font-size: 14px;
    text-align: center;
}

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .wallet_card_div{
        background: linear-gradient(101.43deg, rgba(26, 55, 78, 0.9) -2.83%, #1A374E 73.19%);
        border-radius: 20px;
        padding: 20px;
    }
    .pending_card_div{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.15);
        border-radius: 10px;
        padding: 20px;
    }
    
    .trade_card_div{
        background: #EDF0F6;
        border-radius: 12px;
        padding: 20px;
    }
    .wallet_subtitle{
        color: #fff;
        font-family: 'GraphixSemibold';
        margin-bottom: 0px;
    }
}

/* large screens */
@media (min-width: 1536px) {
    .wallet_card_div{
        background: linear-gradient(101.43deg, rgba(26, 55, 78, 0.9) -2.83%, #1A374E 73.19%);
        border-radius: 20px;
        padding: 30px;
    }
    .pending_card_div{
        background: #FFFFFF;
        box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.15);
        border-radius: 10px;
        padding: 30px;
    }
    .wallet_subtitle{
        color: #fff;
        font-family: 'GraphixSemibold';
        margin-bottom: 0px;
        font-size: 1.25rem;
    }
}