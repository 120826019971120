
.userBg{
        background: #FAFAFB;
  }

  .logo img {
    padding-left: 30px;
    width: 6rem;
  }
  
  .userside {
    height: 100vh;
    top: 0px;
    transform: translateX(0%);
    width: 255px;
    max-height: calc(100% - 0px);
    background: #fff;
    max-width: 100%;
    overflow-x: hidden;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
    position: fixed;
  }
  
  .userside.show {
    height: 100vh;
    top: 0px;
    transform: translateX(0%);
    width: 255px;
    z-index: 99;
    /* box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%),
      0 6px 30px 5px rgb(0 0 0 / 12%); */
      box-shadow: 0px 3px 6px #00000029;
  }
  
  .sidebar-footer-main{
      padding: 0px 20px;
      position: fixed;
      bottom: 30px;
  }
  
  .sidebar-footer{
      background: #F4FBF9;
      border: 1px solid #2B8C34;
      border-radius: 10px;
      padding: 8px;
      /* width: 214px; */
  }
  .sidebar-footer-layout{
      display: flex;
      align-items: center;
  }
  .sidebar-footer-img{
      background: white;
      border: 1px solid #2B8C34;
      border-radius: 10px;
      padding: 8px;
  }
  .sidebar-footer-img img{
      width: 24px;
      height: 24px;
  }
  .sidebarnamedisplay p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }

  .sidebar-footer-name{
      margin-left: 15px;
  }
  .sidebar-footer-name p{
      color: #1A1A1A;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70px;
  }
  .sidebar-footer-name p:last-child{
      color: #4D4D4D;
      font-weight: 500;
      font-size: 12px;
  }
  
  .sidebar-footer-icon{
      cursor: pointer;
      margin-left: 40px;
  }
  
  .sidehead {
    position: absolute;
    height: 60px;
    top: 0px;
    transform: translateY(0px);
    left: 256px;
    right: 0px;
    z-index: 5;
    padding: 30px 20px 20px 20px;
  }
  
  .usermain {
    padding: 60px 0px 60px 256px;
  }
  
  .v-list {
    padding: 0px 25px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(75vh - 0px);
    font-family: 'Averta';
  }
  
  /* list items */
  .v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 50px;
    outline: none;
    padding: 0 15px;
    margin-bottom: 5px;
    position: relative;
    text-decoration: none;
  }
  
  .v-list-item:hover {
    background:  #f0f2f5;
    border-radius: 5px;
  }
  
  .v-list-item:last-child {
    margin-bottom: 40px;
  }
  
  .v-list-item.active {
    /* background: rgba(43, 140, 52, 0.14); */
    border-radius: 5px;
  }
  
  .v-list-item.disabled {
    pointer-events: none;
  }
  
  .v-list-icon {
    color: rgb(212, 236, 255, 0.4);
    margin-right: 8px;
    display: inline-flex;
    min-width: 24px;
  }
  
  .v-list-icon.active {
    margin-right: 8px;
    color: #dde2ff;
    display: inline-flex;
    min-width: 24px;
  }
  
  .v-list-icon img {
    width: 20px;
    height: 20px;
  }
  
  .v-list-title.active {
    font-size: 16px;
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1rem;
    color: #dde2ff;
  }
  
  .v-list-title span.active {
    color: #F7931A;
    font-weight: 400;
  }
  
  .v-list-title span {
    color:  rgba(26, 55, 78, 0.8);
    font-size: 14px;
    font-weight: 400;
  }

  .v-list-title span span.notification-count{
        background: rgba(209, 26, 42, 0.1);
        border-radius: 7px;
        color: #D11A2A;
        font-weight: 600;
        padding: 4px 8px;
        margin-left: 10px;
        font-size: 12px;
    }
  
  nav img.imageStyle {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .titlehead{
    color: #1A374E;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Graphix';
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .back_arrow_div{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .back_arrow_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E1EFE2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .back_arrow_div span{
    color: #666666;
    font-size: 14px;
    font-weight: 500;
  }
  
  .btn_edit{
    padding: 14px 22px;
    background: transparent;
    border: 1px solid #2B8C34;
    border-radius: 5px;
    color: #2B8C34;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .btn_edit:hover{
    color: #2B8C34;
    border: 1px solid #2B8C34;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    border-radius: 0 0 5px 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color:#cccccc;
  }

  .sidebar-footer-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-user-icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .sidebar-logout{
    cursor: pointer;
  }
  
  
  /* ------------------MEDIA QUERY SECTION ------------------------- */
  /* Media query for tablets and screens higher */
  @media (min-width: 768px) {
    .side-logo {
      position: absolute;
      bottom: 50px;
      transform: rotate(-90.04deg);
      right: -50px;
    }
    .side-logo img {
      opacity: 0.1;
      height: 80px;
    }
    
    .sidebar-footer-icon{
        cursor: pointer;
        margin-left: 45px;
    }
    
  .titlehead{
    color: #1A374E;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Graphix';
    margin-bottom: 0px;
  }
  
    .back_arrow_div{
      display: flex;
      align-items: center;
      margin-bottom: 25px;
    }  
  
  }
  
  /* large screens */
  @media (min-width: 1536px) {
    .logo img {
      padding-left: 30px;
    }
    .v-list {
      padding: 0px 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(80vh - 0px);
    }
    .sidebar-footer-main{
      padding: 0px 20px;
      position: fixed;
      bottom: 50px;
    }
  }
  
  /* Media query for smaller phones */
  @media (max-width: 767px) {
    .sidehead {
      position: absolute;
      height: 60px;
      margin-top: 0px;
      transform: translateY(0px);
      left: 0px;
      right: 0px;
      padding: 20px 25px;
    }
    .userside {
      height: 100vh;
      top: 0px;
      transform: translateX(-100%);
      width: 255px;
      z-index: 99;
    }
    .usermain {
      padding: 60px 0px 60px;
    }
    .side-logo {
      position: absolute;
      bottom: 150px;
      transform: rotate(-90.04deg);
      right: -40px;
    }
    .side-logo img {
      opacity: 0.1;
      height: 80px;
    }
  }
  