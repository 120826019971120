
/* ---------------------- NavBar stylesheet ------------------------------ */
.nav_header {
    background: #fafbfe;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    z-index: 99;
  }

.scrolled {
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 1);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: 200ms;
    transition-timing-function: linear;
     box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
}

nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  nav img {
    width: 3.5rem;
    /* width: 50px; */
    /* height: 50px; */
}

.nav_links_head{
    display: none;
    font-family: 'Averta';
}

.nav_links_head li a {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    color: #1A374E;
    line-height: 15px;
    font-weight: 400;
    margin: 0 30px;
    position: relative;
  }

  .nav_links_head li a:hover {
    color: #1A374E;
    text-decoration: none;
  }
  
  .nav_links_head li a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  
  .nav_links_head li a:before {
    position: absolute;
    bottom: -8px;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 2px;
    content: "";
    transition: all 0.3s ease-in-out 0s;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    background-color: #E69A25;
  }


.open_icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #E69A25;
    cursor: pointer;
}

.mobile_menu {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0.5rem;
    transition-property: background-color, border-color;
    transition-duration: 150ms;
    transform-origin: top right;
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
}

.mobile_menu_div {
    border-radius: 0.5rem;
    background: white;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
      font-family: 'Averta';
}

.mobile_container {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .mobile_links {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 0.75rem;
  }
  
  .mobile_links a {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #1A374E;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    text-decoration: none;
  }
  
  .mobile_links a:hover {
    background-color: rgba(249, 250, 251, 1);
    color: #1A374E;
}

/* ---------------- Footer ---------*/
.section_footer{
  background: #1A374E;
  padding: 40px 0px;
}

.section_footer img {
  width: 50px;
  height: 50px;
}

.footer_info{
  color: #FAFBFE;
  font-family: 'Averta';
  font-size: 14px;
  line-height: 160%;
}

.social_icons{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.social_div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}

.social_div:last-child{
  margin-right: 0px;
}

.social_div img{
  width: 15px;
  height: 15px;
}

.social_div.social_facebook{
  background: #3B5998;
}

.social_div.social_whatsapp{
 background: #00E676;
}

.social_div.social_linkedin{
  background: #007AB9;
}

.social_div.social_instagram{
  background: rgba(249, 248, 249, 0.1)
}

.footer_link_title{
  color: #FAFBFE;
  font-family: 'Averta';
}

.footer_links{
  list-style: none;
}

.footer_links li a{
  color: #FAFBFE;
  font-family: 'Averta';
  text-decoration: none;
  font-size: 14px;
  transition: all .15s ease-in-out;
}

.footer_links li p{
  color: #FAFBFE;
  font-family: 'Averta';
  font-size: 14px;
  transition: all .15s ease-in-out;
  /* margin-bottom: 0px; */
}

.footer_links li a:hover{
  color:#E69A25;
}

.footer_copyright{
  color: #FAFBFE;
  font-family: 'Averta';
  font-size: 14px;
}

/* -------HERO SECTION ------------------ */
.hero_contain{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.hero_caption_div{
  padding: 80px 0px 80px 0px;
}

.hero_div{
  position: relative;
  /* background-image: url('../../assets/images/heroo.png'); */
  background-image: linear-gradient(rgba(6, 36, 64, 0.6), rgba(6, 36, 64, 0.6)), url(../../assets/images/heroo.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.hero_img{
 display: none;
}

.hero_dots{
  display: none;
}

.hero_title{
  color: #fff;
  font-family: 'GraphixSemiBold';
  line-height: 40px;
}

.hero_subtitle{
  color: #fff;
  font-family: 'Averta';
  margin-top: 5px;
}

.cta_hero_btn{
  display: flex;
  margin-top: 20px;
}

.btn_signIn{
  color: #fff;
  border: 1px solid #1A374E;
  border-radius: 5px;
  background: #1A374E;
  padding: 12px 30px;
  transition: all .15s ease-in-out;
  font-family: 'Averta';
  width: 100%;
  margin-right: 20px;
}

.btn_signIn:hover{
  background: transparent;
  color: #1A374E;
  border: 1px solid #1A374E;
}

.btn_register{
  color: #fff;
  border: 1px solid #E69A25;
  border-radius: 5px;
  background: #E69A25;
  padding: 12px 30px;
  transition: all .15s ease-in-out;
  font-family: 'Averta';
  width: 100%;
}

.btn_register:hover{
  background: transparent;
  border: 1px solid #E69A25;
  color: #E69A25;
}


/* ----------COINS/RATES Section */
.coins_div{
  padding: 30px 0px;
  background: #1A374E;
  margin-top: 0px;
}

.coin_layout{
 display: flex;
 justify-content: center;
}

.crypto_snapshots{
  display: flex;
  max-width: max-content;
  overflow-x: auto;
}

.crypto_snapshots::-webkit-scrollbar {
 display: none;
}

.crypto_snapshot{
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-right: 1px solid rgba(26, 55, 78, 0.3);
  padding: 0.25rem 1rem;
}

.crypto_snapshot:last-child{
  border-right: none;
}

.crypo_icon{
  background: #FAFBFE;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.crypo_icon img{
  height: 1.5rem;
}

.coin_text{
  margin-bottom: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.trendup_text{
  margin-bottom: 0px;
  font-size: 14px;
  color: #9CC031;
}

.trenddown_text{
  color: #EB042E;
  margin-bottom: 0px;
  font-size: 14px;
}


.rates_div{
  background: #FAFBFE;
  padding: 80px 0px;
}



.rate_title{
  color: #1A374E;
  font-family: 'GraphixSemiBold';
}

.rate_subtitle{
  color: rgba(26, 55, 78, 0.8);
  line-height: 160%;
  font-family: 'Averta';
}

.input_container{
  position: relative;
}

.input_container label{
  font-size: 12px;
  color:  rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
}

.input_style {
  border:  1px solid rgba(26, 55, 78, 0.2);
  border-radius: 6px;
  padding: 14px 20px;
  background: #fff;
  font-size: 15px;
  width: 100%;
  color: #1A374E;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.amount_input{
  border:  1px solid rgba(26, 55, 78, 0.2);
  border-radius: 6px;
  padding: 14px 20px;
  background: #fff;
  font-size: 15px;
  width: 100%;
  font-weight: bold;
  color: #1A374E;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.amount_input:disabled{
  color: #1A374E;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
}


.amount_input::placeholder{
  color: #2C3A50;
}

.input_style:focus{
  border: 1px solid #1A374E;
  background: white;
  box-shadow: none;
  outline: 0 none;
}

.input_style::placeholder{
  color: rgba(44, 58, 80, 0.5);
  font-size: 12px;
  font-family: 'Averta';
}

.amount_style{
  position: absolute;
  right: 0;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(26, 55, 78, 0.2);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 100px;
  height: 52.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount_div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_calc{
  background: #1A374E;
  border-radius: 5px;
  color: #fff;
  padding: 12px 80px;
  transition: all .15s ease-in-out;
  font-family: 'Averta';
  width: 100%;
}

.btn_calc:hover{
  background: rgba(26, 55, 78, 1);
  color: #fff;
}

/* ------- Features section */
.features_div{
  padding: 60px 0px;
}

.feature_title{
  color: #1A374E;
  font-family: 'GraphixSemiBold';
  line-height: 39px;
  letter-spacing: 0.4px;
}

.feature_subtitle{
  color:  rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
}

.feat_box{
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 40px 30px;
}

.feat_icon{
  background: #FAFBFE;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: auto;
}

.feat1_icon{
  background: #FFF2F8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: auto;
}

.feat3_icon{
  background: #DEFFEE;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: auto;
}

.feat2_icon{
  background: #FFF7E3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: auto;
}

.feat_text{
  color: #1A374E;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.004em;
  font-family: 'GraphixMedium';
  max-width: 10rem;
  margin: auto;
}

.feat_subtext{
  color:  rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
  text-align: center;
  margin-bottom: 0px;
}


/* --------- INFO SECTION */
.info_div{
  background: #1A374E;
  padding: 50px 0px;
}

.info_title{
  color: #fff;
  font-family: 'GraphixSemiBold';
}

.info_subtitle{
  color:  #fff;
  font-family: 'Averta';
}

.info_btn{
  color: #fff;
  border: 1px solid #E69A25;
  border-radius: 5px;
  background: #E69A25;
  padding: 12px 30px;
  transition: all .15s ease-in-out;
  font-family: 'Averta';
}

.info_btn:hover{
  background: rgba(230, 154, 37, 0.9);
  color: white;
}

/* -------- STEPS ------- */
.steps_div{
  padding: 50px 0px;
}
.steps_title{
  color: #1A374E;
  font-family: 'GraphixSemiBold';
  line-height: 39px;
  letter-spacing: 0.4px;
}

.steps_subtitle{
  color:  rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
}

.steps_card{
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px 30px;
  transition: all .15s ease-in-out;
  margin-bottom: 20px;
  cursor: pointer;
}

.steps_card:last-child{
  margin-bottom: 0px;
}

.steps_card:hover{
  border-left: 5px solid #FF9900;
}

.steps_content{
  color: rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
  text-align: center;
  margin-bottom: 0px;
}

/* -------- TESTIMONIAL SECTION ------ */
.testimonial_div{
  background: #FAFBFE;
  padding: 50px 0px;
}

.testimonial_title{
  color: #1A374E;
  font-family: 'GraphixSemiBold';
}

.testimonial_subtitle{
  color:  rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
}

.testimonial_sect{
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.test_circle{
  display: none;
}

.testimonial_sect div{
  flex-basis: 50%;
}

.testimonial_img_div{
  margin-right: 20px;
}

.name{
  color: #1A374E;
  line-height: 26px;
  font-family: 'GraphixMedium';
  text-align: center;
}

.content{
  color: rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
  line-height: 160%;
  text-align: center;
}



/* -------- FAQS ----------- */
.faq_div{
  padding: 50px 0px;
}

.faq_title{
  color: #1A374E;
  font-family: 'GraphixSemiBold';
  line-height: 39px;
  letter-spacing: 0.4px;
}

.faq_subtitle{
  color:  rgba(26, 55, 78, 0.8);
  font-family: 'Averta';
}

.faq_accordion .card{
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
}

.faq_question{
  color: #1A374E;
  font-family: 'GraphixMedium';
  font-size: 15px;
}

.faq_answer{
  color: rgba(26, 55, 78, 0.8);
  font-size: 14px;
  line-height: 27px;
}

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
    /* ----------- Navbar */
    .nav_links_head {
        display: flex;
        align-items: center;
        width: auto;
        list-style: none;
        margin-top: 10px;
    }
    /* -----HERO------- */
    .hero_contain{
      padding-left: 3rem;
      padding-right: 0px;
    }
    .cta_hero_btn{
      flex-direction: row;
    }
    .btn_signIn{
      margin-right: 20px;
      width: auto;
    }
    
}




/* sreens higher than tablets */
@media (min-width: 1024px) {
  /* ---- Footer -------- */
  .footer_info{
    max-width: 22rem;
  }
  /* Hero ----------- */

  .hero_div{
    position: relative;
    background-image: none;
  }

  .hero_caption_div{
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 512px;
    justify-content: center;
  }

  .hero_img{
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
  }

  .hero_dots{
    position: absolute;
    left: 0px;
    bottom: 10px;
    display: block;
  }

  
  .hero_title{
    max-width: 25rem;
    line-height: 45px;
    color: #1A374E;
  }


  .hero_subtitle{
    color: rgba(26, 55, 78, 0.8);
  }

  .cta_hero_btn{
    flex-direction: row;
    margin-top: 30px;
    z-index: 2;
  }
  .btn_register{
    width: auto;
  }
  /* --- Rates */

  .coins_div{
    padding: 60px 0px;
    margin-top: 40px;
  }


  .crypto_snapshots{
    display: flex;
  }

  .crypto_snapshot{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-right: 1px solid rgba(26, 55, 78, 0.3);
    padding: 0.25rem 1rem;
  }

  .crypto_snapshot:last-child{
    border-right: none;
  }

  .crypo_icon{
    background: #FAFBFE;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coin_text{
    margin-bottom: 0px;
    color: #fff;
    font-size: 14px;
  }

  .btc_text{
    margin-bottom: 0px;
    font-size: 14px;
    color: #9CC031;
  }



  .btn_calc{
    width: auto;
  }
  .rate_first_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 260px;
  }

  /* --------- INFO SECTION */
  .info_first{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
  }

  .info_first::before{
    position: absolute;
    right: 28%;
    top: 30%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    content: "";
    background-image: url(../../assets/icons/orange-circle.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 120px;
    height: 120px
  }



  .info_div{
    background: #1A374E;
   padding: 0px;
  }
  .info_title{
    max-width: 25rem;
    z-index: 2;
  }
  .info_subtitle{
    max-width: 28rem;
    line-height: 160%;
    z-index: 2;
  }

  /* FEATURES ------ */
  .feature_first{
    display: flex;
    flex-direction: column;
    min-height: 828px;
    justify-content: center;
    position: relative;
  }

  .feature_first::before{
    position: absolute;
    left: 12%;
    top: 42%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    content: "";
    background-image: url(../../assets/icons/arcRect.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 160px;
    height: 160px
  }

  .feature_title{
    max-width: 18rem;
  }
  .feature_subtitle{
    max-width: 26rem;
  }

  /* ------ STEPS */
  .steps_first{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
  }

  .steps_first::before{
    position: absolute;
    left: 12%;
    top: 40%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    content: "";
    background-image: url(../../assets/icons/arcRect.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 160px;
    height: 160px
  }

  .steps_title{
    max-width: 20rem;
  }

  .steps_subtitle{
    max-width: 26rem;
  }

  .steps_div{
    padding: 80px 0px;
  }
  .steps_card{
    max-width: 42rem;
    padding: 30px 0px;
  }
  .steps_content{
    max-width: 28rem;
    margin: auto;
  }
  /* ------- FAQS-------- */
  .faq_first{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
  }

  .faq_first::before{
    position: absolute;
    left: 12%;
    top: 42%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    content: "";
    background-image: url(../../assets/icons/arcRect.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 170px;
    height: 170px
  }

  .faq_title{
    max-width: 20rem;
  }
  .faq_subtitle{
    max-width: 24rem;
  }

  /* ---- TESTIMONIAL */
  .testimonial_sect{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .name{
    text-align: left;
  }

  .content{
    max-width: 25rem;
    text-align: left;
  }

  .test_circle{
    display: block;
    position: absolute;
    left: 30px;
    bottom: 20px;
    z-index: -1;
  }

  
}


/* large screens */
@media (min-width: 1536px) {
  .content{
    max-width: 26rem;
    text-align: left;
  }
}