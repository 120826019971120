
.not_found_div{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.not_found_div img{
    width: 3.5rem;
}


.back_home_title{
    font-family: 'Graphix';
    color: #1A374E;
    font-size: 14px;
    letter-spacing: 0.396px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.not_found_card_div{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.not_found_card{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 60px 30px;
}

.not_found_icon_div{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #F4F4F4;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not_found_icon_div img{
    width: 50px;
    height: 50px;
}

.title{
    color: #1A374E;
}

.subtitle{
    color: rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
    letter-spacing: 0.396px;
}

.not_found_link{
    text-align: center;
    margin-top: 60px;
}
.not_found_link a{
    text-decoration: none;
    color: #F7931A;
    font-size: 14px;
}

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .not_found_card_div{
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
    .not_found_icon_div{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #F4F4F4;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .not_found_icon_div img{
        width: 60px;
        height: 60px;
    }
    
    .not_found_card{
        background: #FFFFFF;
        border-radius: 10px;
        padding: 60px 100px;
    }
    .subtitle{
        color: rgba(26, 55, 78, 0.8);
        font-family: 'Averta';
        letter-spacing: 0.396px;
        max-width: 20rem;
    }
}

/* large screens */
@media (min-width: 1536px) {
    .not_found_card_div{
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
}