.about_heading{
    color: #2C3A50;
    font-family: 'GraphixMedium';
}

.title{
    color: rgba(44, 58, 80, 0.6);
    letter-spacing: 0.3px;
    font-family: 'Averta';
    line-height: 160%;
    max-width: 30rem;
}

.title_two{
    color: #2C3A50;
    font-family: 'GraphixMedium';
}

.office_location{
    color: rgba(44, 58, 80, 0.7);
    font-family: 'Averta';
    line-height: 160%;
    margin-bottom: 0px;
    font-weight: 400;
}

.phone_div{
    display: flex;
    align-items: center;
}

.phone_div img{
    margin-right: 10px;
}

.phone_val{
    margin-bottom: 0px;
    color: rgba(26, 55, 78, 0.7);
    font-family: 'Averta';
    letter-spacing: 0.5px;
    line-height: 160%;
}


.input_container label{
    font-size: 12px;
    color:  rgba(26, 55, 78, 0.8);
    font-family: 'Averta';
}

.input_style {
    border: none;
    border-radius: 6px;
    padding: 14px 20px;
    background: #F4F2F2;
    font-size: 15px;
    width: 100%;
    color: #1A374E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid #1A374E;
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
  .input_style::placeholder{
    color:rgba(44, 58, 80, 0.6);
    font-size: 12px;
    font-family: 'Averta';
  }

  .btn_contact{
    padding: 14px 30px;
    background: #1A374E;
    border: 1px solid #1A374E;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
    font-family: 'Averta';
  }

  .btn_contact:hover{
    color: #fff;
    background: rgba(26, 55, 78, 0.9);
}



/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .title{
        color: rgba(44, 58, 80, 0.6);
        letter-spacing: 0.3px;
        font-family: 'Averta';
        line-height: 160%;
    }

    .contact_form{
        max-width: 32rem;
    }
}


/* large screens */
@media (min-width: 1536px) {
}