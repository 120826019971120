


.notify_card{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 25px 25px;
    box-shadow: 0px 30px 70px rgba(26, 55, 78, 0.01);
    transition: all .15s ease-in-out;
    cursor: pointer;
    min-height: auto;
}

.notify_card:hover{
    border-left: 4px solid #F7931A;
}

.notify_title_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title{
    color: #1A374E;
    font-family: 'GraphixMedium';
    margin-bottom: 0px;
}

.date{
    margin-bottom: 0px;
    color: #1A374E;
    opacity: 0.5; 
    font-family: 'Averta';
    font-size: 14px;
}

.content{
    font-family: 'Averta';
    color: rgba(26, 55, 78, 0.8);
    line-height: 26px;
    margin-bottom: 0px;
}

.pagination_div{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .no_notifications{
      display: flex;
      flex-direction: column;
      min-height: 500px;
      justify-content: center;
      align-items: center;
  }

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {

    .notify_card{
        min-height: 150px;
    }

}


/* large screens */
@media (min-width: 1536px) {
}